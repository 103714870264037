import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
//https://www.npmjs.com/package/markdown-to-jsx
const useStyles = makeStyles({
  root: {
    marginTop: "2rem"
  },
  gradientTextGreen: {
    background: "linear-gradient(100.08deg, #80CBC4 0.31%, #02A591 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
})
const BrandPageTemplate = ({ pageContext }) => {
  const classes = useStyles()
  const title = pageContext.brandName
  const brandInfo = pageContext.html
  return (
    <Layout>
      <div className={classes.root}>
        <Container>
          <Typography
            className={classes.gradientTextGreen}
            align="center"
            variant="h1"
          >
            {title}
          </Typography>
          <GatsbyImage></GatsbyImage>
          {/* inline if statement */}
          <Typography>
            {pageContext.content != null ? (
              <div dangerouslySetInnerHTML={{ __html: brandInfo }} />
            ) : (
              <div></div>
            )}
          </Typography>
        </Container>
      </div>
    </Layout>
  )
}
export default BrandPageTemplate
